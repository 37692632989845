import { customAxios as axios, CORE_AXIOS_PATH, isAuthError } from '@autone/ui';

export async function getUserConfig() {
  try {
    const res = await axios(CORE_AXIOS_PATH).get('/users/me');
    return res;
  } catch (err: unknown) {
    if (isAuthError(err)) {
      return {};
    }
    throw err;
  }
}
