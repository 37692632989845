import { customBaseQuery, isFeatureSwitchEnabled } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

const TAG_TYPES = [
  'Batch',
  'Batches',
  'Products',
  'MonthlyPackKPI',
  'SkuDaysStockOut',
  'Summary',
  'KeyMetricsKPI',
  'SkuProposal',
  'ReorderReasons',
  'Assortment',
  'StockOut',
  'SalesInventoryDetail',
  'InventoryDetail',
  'ReorderHistory',
  'ProductReasons',
];

// Define a service using the base core URL and expected endpoints
export const reorderApi = createApi({
  reducerPath: 'reorderApi',
  tagTypes: TAG_TYPES,
  baseQuery: async (args, api, extraOptions) => {
    // custom query adds auth headers and error handling
    const isV2Enabled = await isFeatureSwitchEnabled('reorder-api-v2');
    const uri = isV2Enabled ? 'v2/reorder' : 'reorder2';
    return customBaseQuery(args, api, extraOptions, uri);
  },
  endpoints: () => ({}),
});
