import { REORDER_ADMIN, REORDER_READONLY, REORDER_STANDARD } from '@autone/ui';

export * from './dimension-options';
export * from './scope';

export const APP_NAME = 'reorder';
export const SIZE_DATA_KEY = 'size';
export const OUT_OF_STOCK_DATE_DATA_KEY = 'Out of stock date';
export const CALCULATION_TYPE_ID = 'scope';
export const RRP_METRIC_ID = 'rrp';
export const IC_METRIC_ID = 'ic';
export const REORDER_REASON_ERROR_CODE = 'INSUFFICIENT_OR_INCORRECT_METADATA';
export const PRODUCT_TABLE_SESSION_STORAGE_KEY =
  'reorder.productsTable.advancedFilters';
export const MAX_REORDER_NAME_CHARACTERS = 25;
export const REORDER_PERMISSIONS = [
  REORDER_ADMIN,
  REORDER_READONLY,
  REORDER_STANDARD,
];
